import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import { CircularProgress } from '@material-ui/core';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';
import LogIn from '../LogIn/index';
import ForgotPassword from '../ForgotPassword/index';
import SetNewPassword from '../SetNewPassword/index';
import Matricula from '../Matricula/index';
import MatriculaExitosa from '../MatriculaExitosa/index';
import Matricular from '../Matricular/index';
import AlumnoActualizar from '../AlumnoActualizar/index';
import MatriculaVer from '../MatriculaVer/index';
import MatriculaDayCareVer from '../MatriculaDayCareVer/index';
import CargoAutomatico from '../CargoAutomatico/index';
import CargoAutomaticoExitoso from '../CargoAutomaticoExitoso/index';
import CargoAutomaticoCrear from '../CargoAutomaticoCrear/index';
import PrivateRoute from '../../shared/components/PrivateRoute';
import ReporteEstadisticas from '../ReporteEstadisticas';
import ReporteEstadisticasNuevos from '../ReporteEstadisticasNuevos';
import { loadAccesos } from '../../redux/actions/appActions';
import saga from '../LogIn/saga';
import injectSaga from '../../utils/injectSaga';
import ReporteMatriculaEstado from '../ReporteMatriculaEstado';
import ReporteCajas from '../ReporteCajas';
import ReporteListado from '../ReporteListado';
import NuevaMatricula from '../NuevaMatricula';
import NuevoAlumnoExitoso from '../NuevoAlumnoExitoso';
import MatriculaPendienteContrato from '../MatriculaPendienteContrato/index';
import SetFirstPassword from '../SetFirstPassword';

const AlumnoRoutes = () => (
  <Switch>
    <Route exact path="/alumno/:id/actualizar" component={AlumnoActualizar} />
  </Switch>
);


const NuevaMatriculaRoutes = () => (
  <Switch>
    <Route exact path="/nueva-matricula" component={NuevaMatricula} />
    <Route exact path="/nueva-matricula/exito" component={NuevoAlumnoExitoso} />
    <Route exact path="/nueva-matricula/pendiente-contrato" component={MatriculaPendienteContrato} />
  </Switch>
);


const MatriculaRoutes = () => (
  <Switch>
    <Route exact path="/matricula" component={Matricula} />
    <Route exact path="/matricula/exito" component={MatriculaExitosa} />
    <Route exact path="/matricula/pendiente-contrato" component={MatriculaPendienteContrato} />
    <Route exact path="/matricula/:id/matricular" component={Matricular} />
    <Route exact path="/matricula/:id/ver" component={MatriculaVer} />
    <Route exact path="/matricula/:id/ver-day-care" component={MatriculaDayCareVer} />
  </Switch>
);

const CargoAutomaticoRoutes = () => (
  <Switch>
    <Route exact path="/cargo" component={CargoAutomatico} />
    <Route exact path="/cargo/exito" component={CargoAutomaticoExitoso} />
    <Route exact path="/cargo/:id/modificar" component={CargoAutomaticoCrear} />
  </Switch>
);

const CambiarPasswordRoutes = () => (
  <Switch>
    <Route exact path="/cambiar-password" component={SetFirstPassword} />
  </Switch>
);

const mapStateToProps = state => ({
  app: state.app,
});

const withSaga = injectSaga({ key: 'wrappedRoutes', saga });

const wrappedRoutes = withSaga(connect(mapStateToProps)(class wrappedRoutes extends React.PureComponent {
  static propTypes = {
    app: PropTypes.shape({}).isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      accesosLoaded: false,
    };
  }

  componentDidMount() {
    this.cargarAccesos();
  }

  componentDidUpdate() {
    this.cargarAccesos();
  }

  cargarAccesos = () => {
    const { dispatch, app } = this.props;
    const { accesosLoaded } = this.state;
    if (app.session && !accesosLoaded) {
      dispatch(loadAccesos(app.session.id));
      this.setState({
        accesosLoaded: true,
      });
    }
  }

  render() {
    const { app } = this.props;

    return app.accesos.length === 0
      ? <CircularProgress className="circular-progress" />
      : (
        <div>
          <Layout />
          <div className="container__wrap">
            {
              _.intersection(app.accesos.find(a => a.opcion === 'matricula').roles, app.session.user.roles).length > 0
              && (
                <Route path="/matricula" component={MatriculaRoutes} />
              )
            }
            {
              _.intersection(app.accesos.find(a => a.opcion === 'nueva-matricula').roles, app.session.user.roles).length > 0
              && (
                <Route path="/nueva-matricula" component={NuevaMatriculaRoutes} />
              )
            }
            {
              _.intersection(app.accesos.find(a => a.opcion === 'matricula').roles, app.session.user.roles).length > 0
              && (
                <Route path="/alumno" component={AlumnoRoutes} />
              )
            }
            {
              _.intersection(app.accesos.find(a => a.opcion === 'matricula').roles, app.session.user.roles).length > 0
              && (
                <Route path="/cambiar-password" component={CambiarPasswordRoutes} />
              )
            }
            {
              _.intersection(app.accesos.find(a => a.opcion === 'cargo').roles, app.session.user.roles).length > 0
              && (
                <Route path="/cargo" component={CargoAutomaticoRoutes} />
              )
            }
            {
              _.intersection(app.accesos.find(a => a.opcion === 'reporte').roles, app.session.user.roles).length > 0
              && (
                <Route exact path="/reportes/estadisticas" component={ReporteEstadisticas} />
              )
            }
            {
              _.intersection(app.accesos.find(a => a.opcion === 'reporte').roles, app.session.user.roles).length > 0
              && (
                <Route exact path="/reportes/estadisticas-nuevos" component={ReporteEstadisticasNuevos} />
              )
            }
            {
              _.intersection(app.accesos.find(a => a.opcion === 'matriculaEstado').roles, app.session.user.roles).length > 0
              && (
                <Route exact path="/reportes/matricula-por-estado" component={ReporteMatriculaEstado} />
              )
            }
            {
              _.intersection(app.accesos.find(a => a.opcion === 'listado').roles, app.session.user.roles).length > 0
              && (
                <Route exact path="/reportes/listado-por-nivel" component={ReporteListado} />
              )
            }
            {
              _.intersection(app.accesos.find(a => a.opcion === 'cajas').roles, app.session.user.roles).length > 0
              && (
                <Route exact path="/reportes/cajas" component={ReporteCajas} />
              )
            }
          </div>
        </div>
      );
  }
}));

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/" component={LogIn} />
        <Route exact path="/olvido-contrasenia" component={ForgotPassword} />
        <Route exact path="/cambiar-contrasenia" component={SetNewPassword} />
        <PrivateRoute path="/" component={wrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
